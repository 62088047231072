<template>
<!-- eslint-disable max-len -->
<!--<section class="Page BonusPolicy">-->
<!--  <h2 class="Title Title&#45;&#45;type-h2 Page-Title">-->
<!--    Bonus <span class="Colored">Policy</span>-->
<!--  </h2>-->
<!--  <div class="Page-Introduction">-->
<!--    <p class="Page-Updated">-->
<!--      Last updated: Feb 02, 2020-->
<!--    </p>-->
<!--    <p>-->
<!--      This Bonus Policy is an integral part of the Terms and Conditions of Use and thus come into effect with the Terms and Conditions of Use. Any general terms of the Terms and Conditions of Use shall apply to the Bonus Policy, except where explicitly excluded.-->
<!--    </p>-->
<!--    <p>-->
<!--      This Bonus Policy is written in English. If this Bonus Policy is translated into another language, the English version will prevail.-->
<!--    </p>-->
<!--  </div>-->
<!--  <div class="Page-Section">-->
<!--    <h3>-->
<!--      General Terms-->
<!--    </h3>-->
<!--    <ol>-->
<!--      <li>-->
<!--        First Deposit/Welcome Bonus can only be claimed once every 72 hours across all Casinos operating under the same license. Failure to comply may result in any winnings to be removed. To find out for which casinos this applies please contact support.-->
<!--      </li>-->
<!--      <li>-->
<!--        If, at any point in time, after reading this policy something is not clear, or you would like to be blocked from receiving our bonuses and/or to have any bonuses removed from your account, please contact our Customer Support Department team before you commence play.-->
<!--      </li>-->
<!--      <li>-->
<!--        If the bonus you were trying to claim does not appear automatically in your player’s account, please contact our Customer Support team, prior to placing any bets, and the bonus shall be credited for you.-->
<!--      </li>-->
<!--      <li>-->
<!--        We reserve the right to cancel any amount in excess of the player's original deposit from a player's withdrawal if the wagering requirement has not been fulfilled or if the player requested to remove the bonus before the wagering requirements were fulfilled.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless stated otherwise in the promotion offer bonuses are only available to players who made at least one successful deposit in their accounts.-->
<!--      </li>-->
<!--      <li>-->
<!--        In the event that a technical misconfiguration or failure of any kind results in the incorrect number of free spins or an incorrectly added Bonus being placed on your account, we reserve the right to correct this by removing any winnings received and re-adding the reward with the correct configuration.-->
<!--      </li>-->
<!--      <li>-->
<!--        A user is not allowed to register more than one account in the system. This means only one account is allowed to be registered per person, household, family, household address, email address, credit card number, e-wallet account or shared computer environment (example: a library, workplace, fraternity, university or school). Any attempt to try to deceive management by registering multiple accounts to gain bonus money will be deemed as bonus abuse and will result in the account(s) being suspended and the removal of your funds.-->
<!--      </li>-->
<!--      <li>-->
<!--        While making bets to wager any deposit bonus/offer (free or deposit) or bonus credited for free spin winnings, the maximum bet permitted is 10% (min 0.10) of the bonus amount or 5 (eg, 5 EUR, 5 GBP, 500 INR) (the lower amount applies). Failure to comply with this rule means you are in breach of the deposit bonus/offer terms and We reserve the right to void and remove any winnings. A bet includes any special bet feature, any gamble function or any other type of wagering activity (regardless of its name).-->
<!--      </li>-->
<!--      <li>-->
<!--        Individual promotions may have additional terms and conditions which will override or contribute to the terms and conditions here stated. Please check carefully all terms and conditions associated with a bonus before taking part. Should the terms and conditions of the individual promotions conflict with the Bonus Policy, the individual promotions terms shall apply.-->
<!--      </li>-->
<!--      <li>-->
<!--        Regardless of the currency that we advertise or display bonus amounts, the bonus will always be credited according to the currency your account is configured to.-->
<!--      </li>-->
<!--      <li>-->
<!--        In accordance with the privacy policy, we regularly send promotional emails with bonus offers. You are only eligible for the bonus if you received the email directly from us. You may unsubscribe from receiving promotions by contacting customer support. If We determine that a specific customer did not receive that particular promotion as an intended exclusive targeted recipient that this promotion was sent out to, we will not honour any payout requests from the customer, even if they have met the wagering criteria.-->
<!--      </li>-->
<!--      <li>-->
<!--        We reserve the right to cancel promotions or bonus offers, of any kind at any time, without prior notice. Any such cancellation will not affect an award already redeemed.-->
<!--      </li>-->
<!--      <li>-->
<!--        All offers you see in your Daily Picks are available to you because of the current state of your account. Offers can become unavailable before the time has expired due to changes within the account caused by transactions made.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless otherwise specified, the minimum deposit to qualify for any deposit bonus is €20 or the equivalent value in any other currency.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless stated otherwise if the last bonus received by a player was a free bonus, a deposit needs to be made first before the player is eligible to use any further free bonuses, even if it was marketed to the player. Any breach of this term can result in blocking from future bonuses and/or removing any winnings that might have been the result of the use of any free bonus.-->
<!--      </li>-->
<!--      <li>-->
<!--        If you receive free money (no deposit bonus/refer a friend bonus/registration bonus etc’) or free spins winnings, unless stated otherwise the maximum amount that you can win or withdraw from it is €100 or two times the bonus amount. The larger amount of the two will be applied. Any winnings greater than the applied amount, including winnings subsequently won from with that money even after any deposit, will be removed from your account. In addition, no winnings accrued in connection with any Free Bonus may be withdrawn until the wagering requirements have been met.-->
<!--      </li>-->
<!--      <li>-->
<!--        A bonus (including the bonus credited from a free spin bonus) is also considered free if your real money balance at the time you were credited the bonus is lower than your most recent deposit.-->
<!--      </li>-->
<!--      <li>-->
<!--        Any bonus which you redeem shall be subject to this Bonus Policy.-->
<!--      </li>-->
<!--      <li>-->
<!--        To receive a ‘Refer a Friend’ bonus the referrer must have deposited within the last 7 days.-->
<!--      </li>-->
<!--      <li>-->
<!--        Free spins are spins which take place that do not reduce funds from your bankroll. For the avoidance of doubt, any mention of Free spins in this bonus policy refers to spins that do not reduce money from your bankroll, regardless of what those free spins are marketed as. This does not include free spins which are a triggered feature within a game.-->
<!--      </li>-->
<!--      <li>-->
<!--        Bonuses issued at the Casino will be reserved for gameplay on this product and cannot be used for gameplay on any other product (for example betting and poker) we have to offer. In addition, gameplay on any other products rather than the Casino will not be counted towards bonus wagering requirements.-->
<!--      </li>-->
<!--      <li>-->
<!--        VIP Points redemption to cash is only available for users who have made at least one successful deposit into to their player’s account.-->
<!--      </li>-->
<!--      <li>-->
<!--        VIP Points redemption to cash is only available for customers who have registered prior 19/03/2017.-->
<!--      </li>-->
<!--      <li>-->
<!--        Each time you place a wager at VegasWinner, you'll earn VIP Points. As your VIP Points accumulate, you can redeem them for instant cash in your real money player account. If you have not placed a wager with money in your real balance for a continuous period of thirty (30) days, we will be entitled to remove all VIP Points from your player's account.-->
<!--      </li>-->
<!--      <li>-->
<!--        Customers from Ukraine, Belarus, Latvia, Russia and Lithuania are not permitted to claim any no deposit offers/free money offers/registration bonus/refer a friend or any other type of offer which can be deemed free-->
<!--      </li>-->
<!--      <li>-->
<!--        If you receive a Claimable Bonus/Free Spins in conjunction with a deposit offer or purchase Free Spins using your Real Money balance you must claim the Bonus or play with the free spins before playing with your available funds (Real Money and Bonus Balance alike). Failure to comply with this may result in any winnings being removed.-->
<!--      </li>-->
<!--      <li>-->
<!--        In the case that you are found in breach of the bonus policy all VIP points accrued, and any winnings from those VIP points will be voided. In case you managed to redeem them the value you redeemed will be manually removed.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless otherwise stated, any no deposit bonus or no deposit free spins offers will only be granted to players who have validated their email address. In the situation that no deposit free spins or no deposit bonus offers which are mistakenly granted to players who have not validated their email we reserve the right to remove any associated winnings.-->
<!--      </li>-->
<!--      <li>-->
<!--        Players are allowed to play in any game while having an active bonus. In certain games bonus balance funds are not available to play with and when you open those games you will only see your real balance. To check for the bonus availability, please refer to the information card for each game in the client.-->
<!--      </li>-->
<!--      <li>-->
<!--        A Pending Bonus is a bonus which will be available for use only after any wagering has been completed. You will see this bonus in your Pending Bonus balance.-->
<!--      </li>-->
<!--      <li>-->
<!--        A non-cashable bonus is a bonus which, once wagering is completed or the bonus is consumed, will not be added to your real balance regardless of whether there was any bonus amount left when it was consumed. Once the wagering is completed/bonus is consumed, any amount left will be removed from your bonus balance.-->
<!--      </li>-->
<!--      <li>-->
<!--        A Network Tournament is a tournament which takes place on multiple Casinos during the same days/hours, and has the same name and prizepool. A player is only allowed to participate in a Network Tournament on one Casino. If the same person (which will be determined solely by us) is playing on a Network Tournament on more than one Casino, only the first Casino that was played on will count towards any tournament prize.-->
<!--      </li>-->
<!--      <li>-->
<!--        Any winnings accrued playing Free Spins offers will be added to your account as a Bonus.-->
<!--      </li>-->
<!--      <li>-->
<!--        If for any reason you do not manage to claim your bonus/free spins after it was added to your account, please contact the customer support department.-->
<!--      </li>-->
<!--      <h3>-->
<!--        Wagering Requirements-->
<!--      </h3>-->
<!--      <li>-->
<!--        Bonus wagering requirements are displayed as Points to Redeem in the Bonus page in the Personal Area.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless stated otherwise in the promotion offer, Bonuses (including winnings from Free Spins) given with a deposit offer carry a wagering requirement of thirty (30) times the total amount, e.g. If you deposit €10 and obtain a €10 bonus, you would have to wager 30 times the aggregate amount, in this case being €20 (deposit + bonus). If a Bonus (including winnings from Free Spins) is not awarded as a part of a deposit offer Or if the total bonus amount (from bonuses and Free Spins winnings) is greater than the deposit amount made to claim the offer, the offer will carry a wagering requirement of sixty (60) times on the bonus amount only.-->
<!--      </li>-->
<!--      <li>-->
<!--        We have 3 different types of wagering that we apply to our bonuses. Unless otherwise stated the bonus has standard wagering terms. Games that are included within the below wagering groups count towards the wagering requirements of a bonus:-->
<!--        <ol>-->
<!--          <li>-->
<!--            Standard wagering: bets placed in any game except Slots and Scratch games do not count towards the wagering requirements.-->
<!--          </li>-->
<!--          <li>-->
<!--            Mixed wagering: 10% of bets placed in non-Slots or Scratch games count towards your wagering requirements.-->
<!--          </li>-->
<!--          <li>-->
<!--            Special Mixed wagering: 50% of bets placed in non-Slots and Scratch games count towards your wagering requirements. Special Mixed wagering is only available on special occasions and to players of certain statuses.-->
<!--          </li>-->
<!--        </ol>-->
<!--      </li>-->
<!--      <li>-->
<!--        When the bonus is added to your account the amount of wagering required is converted to bonus redeem points. The table below shows how much a player must wager in each currency to earn one bonus point.-->
<!--        <table><tbody><tr><td></td><td>0.5 Points</td></tr>-->
<!--        <tr><td>EUR</td><td>1.00</td></tr>-->
<!--        <tr><td>GBP</td><td>1.00</td></tr>-->
<!--        <tr><td>USD</td><td>1.00</td></tr>-->
<!--        <tr><td>CAD</td><td>1.00</td></tr>-->
<!--        <tr><td>AUD</td><td>1.00</td></tr>-->
<!--        <tr><td>DKK</td><td>10.00</td></tr>-->
<!--        <tr><td>SEK</td><td>10.00</td></tr>-->
<!--        <tr><td>NOK</td><td>10.00</td></tr>-->
<!--        <tr><td>ZAR</td><td>10.00</td></tr>-->
<!--        <tr><td>RUB</td><td>70.00</td></tr>-->
<!--        <tr><td>THB</td><td>50.00</td></tr>-->
<!--        <tr><td>INR</td><td>80.00</td></tr>-->
<!--        </tbody></table>-->
<!--      </li>-->
<!--      <li>-->
<!--        Wagering only counts for one bonus at a time according to the following rules:-->
<!--        <ol>-->
<!--          <li>-->
<!--            If there are bonuses restricted to a specific game or games, wagering will contribute to these bonuses first if that specific game is played.-->
<!--          </li>-->
<!--          <li>-->
<!--            If multiple bonuses are active with different wagering conditions, the wagering will be applied to the relevant bonus first in date order.-->
<!--          </li>-->
<!--          <li>-->
<!--            If there are two active bonuses with identical conditions, wagering will contribute to the bonuses in date order.-->
<!--          </li>-->
<!--          <li>-->
<!--            Please note that you will always first wager your real money funds and only when they are fully wagered, you will then start wagering your Bonus Balance.-->
<!--          </li>-->
<!--        </ol>-->
<!--      </li>-->
<!--      <li>-->
<!--        If a bonus has not been used (turnover requirement met or lost) within 30 days, the bonus amount will be cancelled and removed from your account unless you have a real money balance.-->
<!--      </li>-->
<!--      <li>-->
<!--        Unless otherwise stated you have up to 24 hours to meet the wagering requirements for Free Bonuses (non-deposit bonus, refer a friend, free spins received for free) before the bonus and any winnings are removed.-->
<!--      </li>-->
<!--      <li>-->
<!--        In the case that bonus money is wagered and lost, but not all wager requirements were met, the next deposit made to your account, providing you don't have any open games, will automatically reset the bonus as Consumed and it will not be necessary to complete the remaining wager requirements. If you want to continue your game session and make sure that your bonus gets Consumed, please contact support after your deposit. Any abuse of this in order to avoid completing bonus wagering will result in all winnings being removed, and your deposit potentially being confiscated.-->
<!--      </li>-->
<!--      <li>-->
<!--        If you have a bonus consumed/cancelled/expired, either by the system or due to your own request, and the wagering was not completed, all your winnings will be removed including any pending/unclaimed winnings from games/promotions you played whilst you had the bonus.-->
<!--      </li>-->
<!--      <h3>-->
<!--        Irregular Play/Fair Play-->
<!--      </h3>-->
<!--      <li>-->
<!--        We reserve the right to review transaction records and logs from time to time, for any reason whatsoever. If, upon such a review, it appears that a customer is participating in irregular play that We, in our sole discretion deems to be abusive, We reserve the right to revoke the entitlement of such a customer to any promotion.-->
<!--      </li>-->
<!--      <li>-->
<!--        We reserve the right to refuse or void a bonus and/or winnings in the event of funds being cancelled or denied or disputed by any payment service or payment provider or by the user himself.-->
<!--      </li>-->
<!--      <li>-->
<!--        We reserve the right to withhold any withdrawals and/or confiscate all winnings and bonuses for irregular play. The wording ‘Irregular play’ may include but is not wholly restricted to any one or more of the following types of play: Low margin betting, equal betting, zero risk bets or hedge betting are considered irregular gaming when done purposely to exploit bonuses. In addition to this, the following are considered irregular playing patterns:-->
<!--        <ol>-->
<!--          <li>-->
<!--            Increasing the balance then changing the gaming pattern significantly (bet, game type, bet structure, etc) in order to complete the wagering requirements for that bonus.-->
<!--          </li>-->
<!--          <li>-->
<!--            Using any betting system, or placing even money bets on Baccarat and Roulette.-->
<!--          </li>-->
<!--          <li>-->
<!--            Wagering to meet an award in one currency, and then changing currency to a stronger currency before the award is added to your account.-->
<!--          </li>-->
<!--        </ol>-->
<!--      </li>-->
<!--      <li>-->
<!--        If We suspect a player has or is abusing a bonus offer/s, either on his own or in association with partners, We reserve the right to rescind the bonus or bonuses, void any winnings and close the relevant player/s accounts.-->
<!--      </li>-->
<!--      <li>-->
<!--        If you have a pending withdrawal at the time of claiming a free bonus the casino reserves the right to void all subsequent winnings from that bonus including the bonus amount.-->
<!--      </li>-->
<!--      <li>-->
<!--        Promotions and bonuses are there to reward our valued players – We have zero tolerance policy on bonus abuse and fraudulent activity. We reserve the right to block bonuses from any player who is deemed to be abusing our promotions. If fraudulent activity or abuse is suspected, We reserve the right to remove bonuses and winnings from the account.-->
<!--      </li>-->
<!--      <li>-->
<!--        Fair play means depositing with the intention of playing / risking the funds you deposited with.-->
<!--      </li>-->
<!--      <li>-->
<!--        Bonuses/Free Spins Winnings with zero wagering are subject to Fair Play. Any player that takes advantage of such offers, and attempts to withdraw without adhering to Fair Play, will have all their winnings confiscated and be restricted from taking any future offers.-->
<!--      </li>-->
<!--    </ol>-->
<!--  </div>-->
<!--</section>-->
  <section class="Page BonusPolicy">
    <template v-if="isLoading">
      <Loader />
    </template>
    <div v-else-if="isError">
      Sorry, we have some problems, data could not be loaded.
    </div>
    <div v-else v-html="pageContent"></div>
  </section>
</template>

<script>
import getContent from '@/mixins/getContent';

export default {
  name: 'BonusPolicy',
  mixins: [getContent],
  data() {
    return {
      pageType: 'bonus_policy',
    };
  },
  metaInfo() {
    return {
      title: this.$t('metaInfo.bonusPolicy.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.bonusPolicy.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.BonusPolicy {
  h2 {
    color: var(--color-main1);
  }
}
</style>
