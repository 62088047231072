import axios from 'axios';
import { mapState } from 'vuex';
import Loader from '@/components/Loader.vue';
import { contentHost } from '@/config';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      pageContent: '',
    };
  },
  created() {
    if (this.pageType) this.getContent();
  },
  computed: {
    ...mapState(['country', 'token']),
  },
  methods: {
    async getContent() {
      this.isLoading = true;
      const lang = this.$i18n.locale === 'gb' ? 'en' : this.$i18n.locale;
      const query = `${contentHost}/api.php?sid=${this.token}&type=${this.pageType}&country=${this.country}&lang=${lang}`;
      try {
        const res = await axios.get(query);
        this.pageContent = res.data;
      } catch (e) {
        this.isErrored = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
